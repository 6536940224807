import { connect } from "react-redux";
import { useEffect, useState } from "react";
import ExportHistoryLoadingMessage from "./ExportHistoryLoadingMessage";
import useExportsPageControls from "../useExportsPageControls";
import {
  searchGenericExportHistory,
  storeGenericExportHistorySearchResults,
} from "../../Store/mutations";

const ExportHistorySearchHeader = ({
  itemCount,
  pageNumber,
  pageSize,
  refreshExportHistory,
}) => {
  const { handleExportsPageChange, handleExportsPageSizeChange } =
    useExportsPageControls();
  const [hover, setHover] = useState(false);
  useEffect(() => {
    if (itemCount <= 0) {
      return;
    }
    if (pageNumber > itemCount / pageSize) {
      handleExportsPageChange("exportHistory", Math.ceil(itemCount / pageSize));
    }
    if (pageNumber === 0) {
      handleExportsPageChange("exportHistory", 1);
    }
  }, [pageSize, pageNumber, itemCount, handleExportsPageChange]);

  //temporarily disabled pagination element - pending a valid method to get accurate count of search result
  // const getMaxPages = () => {
  //   if (isNaN(pageItemCount)) return 1;
  //   return Math.ceil(pageItemCount < pageSize ? 1 : pageItemCount / pageSize);
  // };

  return (
    <ExportsSearchHeaderPure
      handleExportsPageSizeChange={handleExportsPageSizeChange}
      setHover={setHover}
      hover={hover}
      pageSize={pageSize}
      refreshExportHistory={refreshExportHistory}
    />
  );
};

const mapStateToProps = (state) => ({
  pageNumber: state.pageControls.exportHistory.pageNumber,
  pageSize: state.pageControls.exportHistory.pageSize,
  itemCount: state.exportHistory.length,
});

const mapDispatchToProps = (dispatch) => ({
  refreshExportHistory: () => {
    dispatch(storeGenericExportHistorySearchResults([]));
    dispatch(searchGenericExportHistory());
  },
});

const ConnectedExportHistorySearchHeader = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExportHistorySearchHeader);
export default ConnectedExportHistorySearchHeader;

export const ExportsSearchHeaderPure = ({
  pageSize,
  handleExportsPageSizeChange,
  setHover,
  hover,
  refreshExportHistory,
}) => {
  return (
    <>
      <div id="content-header" className="exportsHeader">
        <div
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          className="exportHeaderLeft"
        >
          <ExportHistoryLoadingMessage hover={hover} />
        </div>
        <div className="flex-right paginationControl exportHeaderRight">
          <select
            defaultValue={pageSize}
            onChange={(e) =>
              handleExportsPageSizeChange("exportHistory", e.target.value)
            }
          >
            <option value={50}>50 Per Page</option>
            <option value={100}>100 Per Page</option>
            <option value={250}>250 Per Page</option>
          </select>
          <button
            className="refreshButton"
            onClick={() => refreshExportHistory()}
          >
            Refresh
          </button>
        </div>
      </div>
    </>
  );
};
