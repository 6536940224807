import React from "react";
import PropTypes from "prop-types";
import BlockInput from "./BlockInput";

const InputText = ({
  action,
  collection,
  disabled,
  id,
  label,
  labelClass,
  tooltip,
  placeholder,
}) => (
  <BlockInput
    action={action}
    collection={collection}
    disabled={disabled}
    id={id}
    label={label}
    labelClass={labelClass}
    tooltip={tooltip}
    type="text"
    uiDestressorTimeout={500}
    placeholder={placeholder}
  />
);

InputText.propTypes = {
  action: PropTypes.string.isRequired,
  collection: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelClass: PropTypes.string,
  tooltip: PropTypes.string,
  placeholder: PropTypes.string,
};

export default InputText;
