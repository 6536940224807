import { combineReducers } from "redux";
import * as mutations from "./mutations";
import defaultState from "./defaultState";
import moment from "moment";

const reducer = combineReducers({
  caseCriteria(caseCriteria = defaultState.caseCriteria, action) {
    switch (action.type) {
      case mutations.RESET:
      case mutations.CASE_RESET:
        return { caseNumber: "" };
      case mutations.CASE_SEARCH:
      case mutations.UPDATE_CASE_CRITERIA:
        return action.caseCriteria;
      default:
        return caseCriteria;
    }
  },

  cases(cases = defaultState.cases, action) {
    switch (action.type) {
      case mutations.RESET:
      case mutations.CASE_RESET:
        return action.state.cases;
      case mutations.CASE_SEARCH:
        return action.cases;
      default:
        return cases;
    }
  },

  caseHistory(caseHistory = defaultState.caseHistory, action) {
    switch (action.type) {
      case mutations.RECIEVE_CASE_HISTORY:
        return [...action.caseHistory];

      case mutations.SUBMIT_CASE_PUBLISH_REQUEST:
        return [
          {
            caseNumber: action.caseNumber,
            techShareReqId: action.techShareReqId,
            submittedOnDateTime: moment().format("YYYY-MM-DD HH:mm:ss"),
          },
          ...caseHistory,
        ];
      default:
        return caseHistory;
    }
  },

  error(error = defaultState.error, action) {
    switch (action.type) {
      case mutations.AGENCY_CONFIG_ERROR:
        return {
          ...action.error,
          message:
            `Network Error - Unable to retrieve Agency Config settings - ` +
            action.error.message,
        };
      case mutations.API_ERROR:
        return action.error;
      default:
        return error;
    }
  },

  exportHistory(exportHistory = defaultState.exportHistory, action) {
    switch (action.type) {
      case mutations.STORE_GENERIC_HISTORY_SEARCH_RESULTS:
        return [...action.results];
      case mutations.UPDATE_EXPORT_STATUS_HISTORY_QUERY:
        return [];
      case mutations.UPDATE_EXPORT_DESTINATION_HISTORY_QUERY:
        return [];
      default:
        return exportHistory;
    }
  },

  incidentCriteria(incidentCriteria = defaultState.incidentCriteria, action) {
    switch (action.type) {
      case mutations.RESET:
      case mutations.INCIDENT_RESET:
        return { incidentDate: "", incidentNumber: "" };
      case mutations.UPDATE_INCIDENT_NUMBER_CRITERIA:
        return { ...incidentCriteria, incidentNumber: action.value };
      case mutations.UPDATE_INCIDENT_DATE_CRITERIA:
        return { ...incidentCriteria, incidentDate: action.value };
      default:
        return incidentCriteria;
    }
  },

  genericExportOptions(
    genericExportOptions = defaultState.genericExportOptions,
    action
  ) {
    switch (action.type) {
      case mutations.SET_GENERIC_EXPORT_OPTIONS:
        return {
          ...genericExportOptions,
          enabled: action.options.enabled,
          configurationIds: action.options.configurationIds,
          types: {
            case: action.options.types.case,
            event: action.options.types.event,
          },
        };
      case mutations.UPDATE_EXPORT_TYPE:
        return { ...genericExportOptions, selectedType: action.selection };
      case mutations.UPDATE_EXPORT_DESTINATION:
        return {
          ...genericExportOptions,
          selectedDestination: action.selection,
        };

      default:
        return genericExportOptions;
    }
  },

  exportHistoryOptions(
    exportHistoryOptions = defaultState.exportHistoryOptions,
    action
  ) {
    switch (action.type) {
      case mutations.UPDATE_EXPORT_STATUS_HISTORY_QUERY:
        return {
          ...exportHistoryOptions,
          selectedStatusHistory: action.selection,
        };
      case mutations.UPDATE_EXPORT_DESTINATION_HISTORY_QUERY:
        return {
          ...exportHistoryOptions,
          selectedDestinationHistory: action.selection,
        };
      default:
        return exportHistoryOptions;
    }
  },

  incidentEvents(incidentEvents = defaultState.incidentEvents, action) {
    switch (action.type) {
      case mutations.RESET:
      case mutations.INCIDENT_RESET:
        return action.state.incidentEvents;
      case mutations.INCIDENT_SEARCH:
      case mutations.INCIDENT_SELECTED:
      case mutations.INCIDENT_DESELECTED:
      case mutations.INCIDENT_RESPONSE_SELECTED:
        return defaultState.incidentEvents;
      case mutations.INCIDENT_EVENT_SEARCH:
        return action.incidentEvents;
      default:
        return incidentEvents;
    }
  },

  incidentEventSearchFilter(
    incidentEventSearchFilter = defaultState.incidentEventSearchFilter,
    action
  ) {
    switch (action.type) {
      case mutations.RESET:
        return action.state.incidentEventSearchFilter;
      case mutations.INCIDENT_SEARCH:
      case mutations.INCIDENT_SELECTED:
      case mutations.INCIDENT_DESELECTED:
        return defaultState.incidentEventSearchFilter;
      case mutations.INCIDENT_RESPONSE_SELECTED:
      case mutations.INCIDENT_EVENT_SEARCH:
        return action.incidentEventSearchFilter;
      default:
        return incidentEventSearchFilter;
    }
  },

  incidentEventForExportSearchCriteria(
    incidentEventForExportSearchCriteria = defaultState.incidentEventForExportSearchCriteria,
    action
  ) {
    switch (action.type) {
      case mutations.UPDATE_OFFICER_FOR_INCIDENT_EVENT_EXPORT_SEARCH:
        return {
          ...incidentEventForExportSearchCriteria,
          officerIds: action.selection,
        };
      case mutations.UPDATE_STARTDATE_FOR_INCIDENT_EVENT_EXPORT_SEARCH:
        return {
          ...incidentEventForExportSearchCriteria,
          searchDateBegin: action.start,
        };
      case mutations.UPDATE_ENDDATE_FOR_INCIDENT_EVENT_EXPORT_SEARCH:
        return {
          ...incidentEventForExportSearchCriteria,
          searchDateEnd: action.end,
        };
      default:
        return incidentEventForExportSearchCriteria;
    }
  },

  incidentNumber(incidentNumber = defaultState.incidentNumber, action) {
    switch (action.type) {
      case mutations.RESET:
      case mutations.INCIDENT_RESET:
        return action.state.incidentNumber;
      case mutations.INCIDENT_SEARCH:
      case mutations.INCIDENT_DESELECTED:
        return defaultState.incidentNumber;
      case mutations.INCIDENT_SELECTED:
        return action.incidentNumber;
      default:
        return incidentNumber;
    }
  },

  incidentResponseId(
    incidentResponseId = defaultState.incidentResponseId,
    action
  ) {
    switch (action.type) {
      case mutations.RESET:
      case mutations.INCIDENT_RESET:
        return action.state.incidentResponseId;
      case mutations.INCIDENT_SEARCH:
      case mutations.INCIDENT_SELECTED:
      case mutations.INCIDENT_DESELECTED:
        return defaultState.incidentResponseId;
      case mutations.INCIDENT_RESPONSE_SELECTED:
        return action.incidentResponseId;
      default:
        return incidentResponseId;
    }
  },

  incidentResponses(
    incidentResponses = defaultState.incidentResponses,
    action
  ) {
    switch (action.type) {
      case mutations.RESET:
      case mutations.INCIDENT_RESET:
        return action.state.incidentResponses;
      case mutations.INCIDENT_SEARCH:
      case mutations.INCIDENT_DESELECTED:
        return defaultState.incidentResponses;
      case mutations.INCIDENT_SELECTED:
        return action.incidentResponses;
      default:
        return incidentResponses;
    }
  },

  incidents(incidents = defaultState.incidents, action) {
    switch (action.type) {
      case mutations.RESET:
      case mutations.INCIDENT_RESET:
        return action.state.incidents;
      case mutations.INCIDENT_SEARCH:
        return action.incidents;
      default:
        return incidents;
    }
  },

  integrationEnabled(
    integrationEnabled = defaultState.integrationEnabled,
    action
  ) {
    switch (action.type) {
      case mutations.INTEGRATION_ENABLED:
        return true;
      case mutations.INTEGRATION_DISABLED:
        return false;
      default:
        return integrationEnabled;
    }
  },

  loadingMessage(loadingMessage = defaultState.loadingMessage, action) {
    switch (action.type) {
      case mutations.SET_LOADING_MESSAGE:
        return action.message;
      case mutations.REQUEST_RESET:
      case mutations.REQUEST_INCIDENT_RESET:
      case mutations.REQUEST_CASE_RESET:
        return "Resetting";
      case mutations.REQUEST_INCIDENT_SEARCH:
      case mutations.REQUEST_INCIDENT_DESELECTION:
        return `Searching Incident`;
      case mutations.REQUEST_INCIDENT_SELECTION:
        return `Searching Incident Responses`;
      case mutations.REQUEST_INCIDENT_RESPONSE_SELECTION:
      case mutations.REQUEST_INCIDENT_EVENT_SEARCH:
        return `Searching Incident Events`;
      case mutations.REQUEST_ASSIGN_INCIDENT_NUMBER:
        return `Updating Event`;
      case mutations.REQUEST_EXPORT_INCIDENT:
        return `Exporting Incident`;
      case mutations.REQUEST_OFFICER_LIST:
        return `Loading Officer List`;
      case mutations.REQUEST_CASE_HISTORY:
        return `Loading Case History`;
      case mutations.REQUEST_CASE_SEARCH:
        return `Loading Case Search`;
      case mutations.RESET:
      case mutations.CASE_RESET:
      case mutations.INCIDENT_RESET:
      case mutations.INCIDENT_SEARCH:
      case mutations.INCIDENT_SELECTED:
      case mutations.INCIDENT_DESELECTED:
      case mutations.INCIDENT_RESPONSE_SELECTED:
      case mutations.INCIDENT_EVENT_SEARCH:
      case mutations.ASSIGN_INCIDENT_NUMBER:
      case mutations.EXPORT_INCIDENT:
      case mutations.OFFICER_LIST:
      case mutations.CASE_SEARCH:
      case mutations.RECIEVE_CASE_HISTORY:
      case mutations.STORE_GENERIC_HISTORY_SEARCH_RESULTS:
        return false;
      default:
        return loadingMessage;
    }
  },

  pageControls(pageControls = defaultState.pageControls, action) {
    switch (action.type) {
      case mutations.UPDATE_PAGE_SIZE:
        return {
          ...pageControls,
          [action.pageName]: {
            ...pageControls[action.pageName],
            pageSize: action.pageSize,
          },
        };
      case mutations.UPDATE_PAGE_NUMBER:
        return {
          ...pageControls,
          [action.pageName]: {
            ...pageControls[action.pageName],
            pageNumber: action.pageNumber,
          },
        };
      case mutations.UPDATE_PAGE_FILTER:
        return {
          ...pageControls,
          [action.pageName]: {
            ...pageControls[action.pageName],
            pageFilter: action.pageFilter,
          },
        };
      case mutations.UPDATE_PAGE_ITEMCOUNT:
        return {
          ...pageControls,
          [action.pageName]: {
            ...pageControls[action.pageName],
            pageItemCount: action.pageItemCount,
          },
        };
      case mutations.SET_RECEIVED_ALL_EXPORT_HISTORY:
        return {
          ...pageControls,
          exportHistory: {
            ...pageControls["exportHistory"],
            receivedAllHistory: action.value,
          },
        };
      case mutations.UPDATE_OFFICER_FILTER:
        return {
          officer: { ...pageControls.officer, filter: action.filter },
        };
      case mutations.UPDATE_OFFICER_PAGENUMBER:
        return {
          officer: { ...pageControls.officer, pageNumber: action.pageNumber },
        };
      case mutations.UPDATE_OFFICER_PAGESIZE:
        return {
          officer: { ...pageControls.officer, pageSize: action.pageSize },
        };
      default:
        return pageControls;
    }
  },

  officers(officers = defaultState.officers, action) {
    switch (action.type) {
      case mutations.REQUEST_OFFICER_LIST:
        return ["loading"];
      case mutations.OFFICER_LIST:
      case mutations.UPDATE_OFFICER_MAPPING:
        return action.officers;
      default:
        return officers;
    }
  },

  secondaryTags(secondaryTags = defaultState.secondaryTags, action) {
    switch (action.type) {
      case mutations.INCIDENT_SELECTED:
        return {
          ...secondaryTags,
          incidentNumber: action.incidentNumber,
          rmsNumber: action.rmsNumber,
          natureCode: action.natureCode,
        };
      case mutations.INCIDENT_SEARCH:
      case mutations.INCIDENT_DESELECTED:
        return defaultState.secondaryTags;
      default:
        return secondaryTags;
    }
  },

  taggingOptions(taggingOptions = defaultState.taggingOptions, action) {
    switch (action.type) {
      case mutations.SET_TAGGING_OPTIONS:
        return {
          ...taggingOptions,
          disableMultiTag: action.disableMultiTag,
          delimiter: action.delimiter,
          incidentNumberTagName: action.incidentNumberTagName,
          rmsNumberTagName: action.rmsNumberTagName,
          natureCodeTagName: action.natureCodeTagName,
        };

      default:
        return taggingOptions;
    }
  },

  techSharePublishEnabled(
    techSharePublishEnabled = defaultState.techSharePublishEnabled,
    action
  ) {
    switch (action.type) {
      case mutations.ENABLE_TECHSHARE_UPLOAD:
        return true;
      default:
        return techSharePublishEnabled;
    }
  },

  userName(userName = defaultState.userName, action) {
    switch (action.type) {
      case mutations.SET_USERNAME:
        return action.value;
      default:
        return userName;
    }
  },
});

export default reducer;
