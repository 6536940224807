import React, { useEffect } from "react";
import { connect } from "react-redux";
import { searchGenericExportHistory } from "../../Store/mutations";
import ExportHistorySearchHeader from "./ExportHistorySearchHeader";
import ExportHistoryControlPanel from "./ExportHistoryControlPanel";
import ExportHistorySearchResults from "./ExportHistorySearchResults";

import "../Exports.css";

export const ExportHistoryPage = ({
  pageSize,
  selectedStatus,
  selectedDestination,
  exportHistory,
  genericExportSearchRequest,
}) => {
  useEffect(() => {
    if (exportHistory.length === 0) genericExportSearchRequest();
  }, [
    selectedDestination,
    selectedStatus,
    genericExportSearchRequest,
    pageSize,
    exportHistory.length,
  ]);
  return (
    <div id="exports-content-outer-wrapper">
      <div id="content-header-container" className="indented">
        <ExportHistorySearchHeader pageName="exportHistory" />
      </div>
      <ExportHistoryControlPanel />
      <ExportHistorySearchResults />
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectedStatus: state.exportHistoryOptions.selectedStatusHistory,
  selectedDestination: state.exportHistoryOptions.selectedDestinationHistory,
  pageSize: state.pageControls.exportHistory.pageSize,
  exportHistory: state.exportHistory,
});

const mapDispatchToProps = (dispatch) => ({
  genericExportSearchRequest: () => dispatch(searchGenericExportHistory()),
});

const connectedExportHistoryPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExportHistoryPage);
export default connectedExportHistoryPage;
