//Cases
export const UPDATE_CASEHISTORY_TABLE = "UPDATE_CASEHISTORY_TABLE";
export const SUBMIT_CASE_PUBLISH_REQUEST = "SUBMIT_CASE_PUBLISH_REQUEST";
export const UPDATE_CASE_FILTER = "UPDATE_CASE_FILTER";
export const UPDATE_CASEHISTORY_FILTER = "UPDATE_CASEHISTORY_FILTER";
export const ENABLE_TECHSHARE_UPLOAD = "ENABLE_TECHSHARE_UPLOAD";
export const UPDATE_CASE_COUNT = "UPDATE_CASE_COUNT";
export const UPDATE_CASE_PAGENUMBER = "UPDATE_CASE_PAGENUMBER";
export const UPDATE_CASE_PAGESIZE = "UPDATE_CASE_PAGESIZE";
export const REQUEST_CASE_SEARCH = "REQUEST_CASE_SEARCH";
export const CASE_SEARCH = "CASE_SEARCH";
export const REQUEST_CASE_HISTORY = "REQUEST_CASE_HISTORY";
export const RECIEVE_CASE_HISTORY = "RECIEVE_CASE_HISTORY";
//error action types
export const AGENCY_CONFIG_ERROR = "AGENCY_CONFIG_ERROR";
export const API_ERROR = "API_ERROR";

//generic exports page
export const SET_GENERIC_EXPORT_OPTIONS = "SET_GENERIC_EXPORT_OPTIONS";
export const SUBMIT_GENERIC_EXPORT_REQUEST = "SUBMIT_GENERIC_EXPORT_REQUEST";
export const SEARCH_GENERIC_EXPORT_HISTORY = "SEARCH_GENERIC_EXPORT_HISTORY";
export const STORE_GENERIC_HISTORY_SEARCH_RESULTS =
  "STORE_GENERIC_HISTORY_SEARCH_RESULTS";
export const UPDATE_EXPORT_TYPE = "UPDATE_EXPORT_TYPE";
export const UPDATE_EXPORT_DESTINATION = "UPDATE_EXPORT_DESTINATION";
export const UPDATE_CASE_CRITERIA = "UPDATE_CASE_CRITERIA";
export const UPDATE_EXPORT_STATUS_HISTORY_QUERY =
  "UPDATE_EXPORT_STATUS_HISTORY_QUERY";
export const UPDATE_EXPORT_DESTINATION_HISTORY_QUERY =
  "UPDATE_EXPORT_DESTINATION_HISTORY_QUERY";
export const REQUEST_INCIDENT_EVENT_FOR_EXPORT_SEARCH =
  "REQUEST_INCIDENT_EVENT_FOR_EXPORT_SEARCH";
export const UPDATE_OFFICER_FOR_INCIDENT_EVENT_EXPORT_SEARCH =
  "UPDATE_OFFICER_FOR_INCIDENT_EVENT_EXPORT_SEARCH";
export const UPDATE_STARTDATE_FOR_INCIDENT_EVENT_EXPORT_SEARCH =
  "UPDATE_STARTDATE_FOR_INCIDENT_EVENT_EXPORT_SEARCH";
export const UPDATE_ENDDATE_FOR_INCIDENT_EVENT_EXPORT_SEARCH =
  "UPDATE_ENDDATE_FOR_INCIDENT_EVENT_EXPORT_SEARCH";
export const SET_RECEIVED_ALL_EXPORT_HISTORY =
  "SET_RECEIVED_ALL_EXPORT_HISTORY";
//incidents
export const REQUEST_INCIDENT_SEARCH = "REQUEST_INCIDENT_SEARCH";
export const INCIDENT_SEARCH = "INCIDENT_SEARCH";
export const UPDATE_INCIDENT_NUMBER_CRITERIA =
  "UPDATE_INCIDENT_NUMBER_CRITERIA";
export const UPDATE_INCIDENT_DATE_CRITERIA = "UPDATE_INCIDENT_DATE_CRITERIA";
export const REQUEST_INCIDENT_SELECTION = "REQUEST_INCIDENT_SELECTION";
export const INCIDENT_SELECTED = "INCIDENT_SELECTED";
export const REQUEST_INCIDENT_DESELECTION = "REQUEST_INCIDENT_DESELECTION";
export const INCIDENT_DESELECTED = "INCIDENT_DESELECTED";
export const REQUEST_INCIDENT_RESPONSE_SELECTION =
  "REQUEST_INCIDENT_RESPONSE_SELECTION";
export const INCIDENT_RESPONSE_SELECTED = "INCIDENT_RESPONSE_SELECTED";
export const REQUEST_INCIDENT_EVENT_SEARCH = "REQUEST_INCIDENT_EVENT_SEARCH";
export const INCIDENT_EVENT_SEARCH = "INCIDENT_EVENT_SEARCH";
export const REQUEST_EXPORT_INCIDENT = "REQUEST_EXPORT_INCIDENT";
export const EXPORT_INCIDENT = "EXPORT_INCIDENT";
export const REQUEST_ASSIGN_INCIDENT_NUMBER = "REQUEST_ASSIGN_INCIDENT_NUMBER";
export const ASSIGN_INCIDENT_NUMBER = "ASSIGN_INCIDENT_NUMBER";
export const INTEGRATION_ENABLED = "INTEGRATION_ENABLED";
export const INTEGRATION_DISABLED = "INTEGRATION_DISABLED";

//incident page controls
export const UPDATE_INCIDENT_COUNT = "UPDATE_INCIDENT_COUNT";
export const UPDATE_INCIDENT_PAGENUMBER = "UPDATE_INCIDENT_PAGENUMBER";
export const UPDATE_INCIDENT_PAGESIZE = "UPDATE_INCIDENT_PAGESIZE";

//loading message (for tests)
export const SET_LOADING_MESSAGE = "SET_LOADING_MESSAGE";
//officers
export const REQUEST_OFFICER_LIST = "REQUEST_OFFICER_LIST";
export const OFFICER_LIST = "OFFICER_LIST";
export const OFFICER_LIST_HAS_LOADED = "OFFICER_LIST_HAS_LOADED";
export const REQUEST_UPDATE_OFFICER_MAPPING = "REQUEST_UPDATE_OFFICER_MAPPING";
export const UPDATE_OFFICER_MAPPING = "UPDATE_OFFICER_MAPPING";

//officer page controls
export const UPDATE_OFFICER_FILTER = "UPDATE_OFFICER_FILTER";
export const UPDATE_OFFICER_PAGENUMBER = "UPDATE_OFFICER_PAGENUMBER";
export const UPDATE_OFFICER_PAGESIZE = "UPDATE_OFFICER_PAGESIZE";

//resets
export const REQUEST_RESET = "REQUEST_RESET";
export const RESET = "RESET";
export const REQUEST_INCIDENT_RESET = "REQUEST_INCIDENT_RESET";
export const INCIDENT_RESET = "INCIDENT_RESET";
export const REQUEST_CASE_RESET = "REQUEST_CASE_RESET";
export const CASE_RESET = "CASE_RESET";

//tagging options
export const SET_TAGGING_OPTIONS = "SET_TAGGING_OPTIONS";

//unified page controls
export const UPDATE_PAGE_SIZE = "UPDATE_PAGE_SIZE";
export const UPDATE_PAGE_NUMBER = "UPDATE_PAGE_NUMBER";
export const UPDATE_PAGE_ITEMCOUNT = "UPDATE_PAGE_ITEMCOUNT";
export const UPDATE_PAGE_FILTER = "UPDATE_PAGE_FILTER";

//username
export const SET_USERNAME = "SET_USERNAME";

//actions
export const requestIncidentSearch = () => ({ type: REQUEST_INCIDENT_SEARCH });
export const requestCaseSearch = () => ({ type: REQUEST_CASE_SEARCH });
export const requestCaseHistory = () => ({ type: REQUEST_CASE_HISTORY });

export const incidentSearch = (incidents) => ({
  type: INCIDENT_SEARCH,

  incidents,
});

export const updateIncidentNumberCriteria = (value) => ({
  type: UPDATE_INCIDENT_NUMBER_CRITERIA,
  value,
});

export const updateIncidentDateCriteria = (value) => ({
  type: UPDATE_INCIDENT_DATE_CRITERIA,
  value,
});

export const caseSearch = (caseCriteria, cases) => ({
  type: CASE_SEARCH,
  caseCriteria,
  cases,
});

export const updateCaseCriteria = (caseCriteria) => ({
  type: UPDATE_CASE_CRITERIA,
  caseCriteria,
});

export const receiveCaseHistory = (caseHistory) => ({
  type: RECIEVE_CASE_HISTORY,
  caseHistory,
});

export const requestIncidentSelection = (
  incidentNumber,
  rmsNumber,
  natureCode
) => ({
  type: REQUEST_INCIDENT_SELECTION,
  incidentNumber,
  rmsNumber,
  natureCode,
});

export const incidentSelected = (
  incidentNumber,
  rmsNumber,
  natureCode,
  incidentResponses
) => ({
  type: INCIDENT_SELECTED,
  incidentNumber,
  rmsNumber,
  natureCode,
  incidentResponses,
});

export const requestIncidentDeselection = () => ({
  type: REQUEST_INCIDENT_DESELECTION,
});

export const incidentDeselected = () => ({
  type: INCIDENT_DESELECTED,
});

export const requestIncidentResponseSelection = (incidentResponseId) => ({
  type: REQUEST_INCIDENT_RESPONSE_SELECTION,
  incidentResponseId,
});

export const incidentResponseSelected = (
  incidentResponseId,
  incidentEventSearchFilter,
  incidentEvents
) => ({
  type: INCIDENT_RESPONSE_SELECTED,
  incidentResponseId,
  incidentEventSearchFilter,
  incidentEvents,
});

export const requestIncidentEventSearch = (name, value) => ({
  type: REQUEST_INCIDENT_EVENT_SEARCH,
  name,
  value,
});

export const incidentEventSearch = (
  incidentEventSearchFilter,
  incidentEvents
) => ({
  type: INCIDENT_EVENT_SEARCH,
  incidentEventSearchFilter,
  incidentEvents,
});

export const requestExportIncident = (incidentNumber, referenceLabel) => ({
  type: REQUEST_EXPORT_INCIDENT,
  incidentNumber,
  referenceLabel,
});

export const exportIncident = () => ({
  type: EXPORT_INCIDENT,
});

export const requestAssignIncidentNumber = (incidentEventId) => ({
  type: REQUEST_ASSIGN_INCIDENT_NUMBER,
  incidentEventId,
});

export const assignIncidentNumber = (incidentEvents) => ({
  type: ASSIGN_INCIDENT_NUMBER,
  incidentEvents,
});

export const requestReset = () => ({
  type: REQUEST_RESET,
});

export const reset = (state) => ({
  type: RESET,
  state,
});

export const requestIncidentReset = () => ({
  type: REQUEST_INCIDENT_RESET,
});

export const incidentReset = (state) => ({
  type: INCIDENT_RESET,
  state,
});

export const requestCaseReset = () => ({
  type: REQUEST_CASE_RESET,
});

export const caseReset = (state) => ({
  type: CASE_RESET,
  state,
});

export const requestOfficerList = (skipCount = 0, takeCount = 1500) => ({
  type: REQUEST_OFFICER_LIST,
  skipCount,
  takeCount,
});

export const officerList = (officers) => ({
  type: OFFICER_LIST,
  officers,
});

export const requestUpdateOfficerMapping = (dispatchOfficerId, officerId) => ({
  type: REQUEST_UPDATE_OFFICER_MAPPING,
  dispatchOfficerId,
  officerId,
});

export const agencyConfigError = (error) => ({
  type: AGENCY_CONFIG_ERROR,
  error,
});

export const apiError = (error) => ({
  type: API_ERROR,
  error,
});

export const updatePageSize = (pageName, pageSize) => ({
  type: UPDATE_PAGE_SIZE,
  pageName,
  pageSize,
});

export const updatePageNumber = (pageName, pageNumber) => ({
  type: UPDATE_PAGE_NUMBER,
  pageName,
  pageNumber,
});

export const updatePageFilter = (pageName, pageFilter) => ({
  type: UPDATE_PAGE_FILTER,
  pageName,
  pageFilter,
});

export const updatePageItemCount = (pageName, pageItemCount) => ({
  type: UPDATE_PAGE_ITEMCOUNT,
  pageName,
  pageItemCount,
});

export const setTaggingOptions = (
  delimiter,
  disableMultiTag,
  incidentNumberTagName,
  rmsNumberTagName,
  natureCodeTagName
) => ({
  type: SET_TAGGING_OPTIONS,
  delimiter,
  disableMultiTag,
  incidentNumberTagName,
  rmsNumberTagName,
  natureCodeTagName,
});

export const integrationEnabled = () => ({
  type: INTEGRATION_ENABLED,
});

export const integrationDisabled = () => ({
  type: INTEGRATION_DISABLED,
});

export const updateCaseHistoryTable = (caseData) => ({
  type: UPDATE_CASEHISTORY_TABLE,
  caseData,
});

export const submitCasePublishRequest = (caseNumber, techShareReqId) => ({
  type: SUBMIT_CASE_PUBLISH_REQUEST,
  caseNumber,
  techShareReqId,
});

export const enabletechSharePublish = () => ({
  type: ENABLE_TECHSHARE_UPLOAD,
});

export const setGenericExportOptions = (options) => ({
  type: SET_GENERIC_EXPORT_OPTIONS,
  options,
});

export const updateExportType = (selection) => ({
  type: UPDATE_EXPORT_TYPE,
  selection,
});

export const updateExportDestination = (selection) => ({
  type: UPDATE_EXPORT_DESTINATION,
  selection,
});

export const updateExportStatusHistoryQuery = (selection) => ({
  type: UPDATE_EXPORT_STATUS_HISTORY_QUERY,
  selection,
});

export const updateExportDestinationHistoryQuery = (selection) => ({
  type: UPDATE_EXPORT_DESTINATION_HISTORY_QUERY,
  selection,
});

export const RequestIncidentEventForExportSearch = () => ({
  type: REQUEST_INCIDENT_EVENT_FOR_EXPORT_SEARCH,
});

export const updateOfficerForIncidentEventForExportSearchCriteria = (
  selection
) => ({
  type: UPDATE_OFFICER_FOR_INCIDENT_EVENT_EXPORT_SEARCH,
  selection,
});

export const updateStartDateForIncidentEventForExportSearchCriteria = (
  start
) => ({
  type: UPDATE_STARTDATE_FOR_INCIDENT_EVENT_EXPORT_SEARCH,
  start,
});

export const updateEndDateForIncidentEventForExportSearchCriteria = (end) => ({
  type: UPDATE_ENDDATE_FOR_INCIDENT_EVENT_EXPORT_SEARCH,
  end,
});

export const submitGenericExportRequest = (
  destinationType,
  exportType,
  sourceId,
  label,
  userName
) => ({
  type: SUBMIT_GENERIC_EXPORT_REQUEST,
  destinationType,
  exportType,
  sourceId,
  label,
  userName,
});

export const searchGenericExportHistory = () => ({
  type: SEARCH_GENERIC_EXPORT_HISTORY,
});

export const storeGenericExportHistorySearchResults = (results) => ({
  type: STORE_GENERIC_HISTORY_SEARCH_RESULTS,
  results,
});

export const setLoadingMessage = (message) => ({
  type: SET_LOADING_MESSAGE,
  message,
});

export const setReceivedAllExportHistory = (value) => ({
  type: SET_RECEIVED_ALL_EXPORT_HISTORY,
  value,
});

export const setUserName = (value) => ({
  type: SET_USERNAME,
  value,
});
