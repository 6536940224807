import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { DebounceInput } from "react-debounce-input";
import _ from "lodash";

export const Input = ({
  checked,
  disabled,
  id,
  name,
  placeholder,
  readOnly,
  type,
  uiDestressorTimeout,
  value,
  onChange,
}) =>
  uiDestressorTimeout ? (
    <DebounceInput
      debounceTimeout={uiDestressorTimeout}
      disabled={disabled}
      id={id}
      name={name}
      onChange={(event) => {
        event.persist();
        return onChange(event);
      }}
      placeholder={placeholder}
      type={type}
      value={value}
    />
  ) : (
    <input
      checked={checked}
      disabled={disabled}
      id={id}
      name={name}
      onChange={(event) => {
        event.persist();
        return onChange(event);
      }}
      placeholder={placeholder}
      readOnly={readOnly}
      type={type}
      value={value}
    />
  );

Input.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  type: PropTypes.oneOf([
    "button",
    "checkbox",
    "color",
    "date",
    "datetime-local",
    "email",
    "file",
    "hidden",
    "image",
    "month",
    "number",
    "password",
    "radio",
    "range",
    "reset",
    "search",
    "submit",
    "tel",
    "text",
    "time",
    "url",
    "week",
  ]).isRequired,
  uiDestressorTimeout: PropTypes.number,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  switch (ownProps.type) {
    case "checkbox":
      return {
        checked: state[ownProps.collection][ownProps.id],
        ...ownProps,
      };
    case "radio":
      return {
        checked: state[ownProps.collection][ownProps.name] === ownProps.value,
        ...ownProps,
      };
    default:
      return {
        value: state[ownProps.collection][ownProps.id],
        ...ownProps,
      };
  }
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: _.debounce((event) => {
    let name;
    let value;
    switch (ownProps.type) {
      case "radio":
        name = ownProps.name;
        value = ownProps.value;
        break;
      case "checkbox":
        name = ownProps.id;
        value = !event.target.checked;
        break;
      default:
        name = ownProps.id;
        value = event.target.value;
        break;
    }
    dispatch(
      {
        type: ownProps.action,
        name,
        value,
      },
      ownProps.uiDestressorTimeout
    );
  }),
});

const ConnectedInput = connect(mapStateToProps, mapDispatchToProps)(Input);

export default ConnectedInput;
