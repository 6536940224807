export const exportsSortByOptions = {
  exportId: "exportId",
  createdByName: "createdByName",
  createdOn: "createdOn",
  caseNumber: "caseNumber",
};

export const exportHistorySortByOptions = {
  genericExportId: "genericExportId",
  status: "status",
  created: "created",
};

export const sortOrderOptions = {
  ascending: "ascending",
  descending: "descending",
};
