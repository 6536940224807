const ExportHistoryTableRow = ({ exportHistoryData }) => {
  const {
    id,
    exportType,
    sourceId,
    destinationType,
    label,
    created,
    completed,
    status,
    requestedBy
  } = exportHistoryData;

  var eventIds = exportHistoryData.items.map((item) => {
    if (item.exportItemType === "EventStream") return item.exportItemId;
    else return null;
  });
  var streamCount = eventIds.filter(Boolean).length;
  var eventCount = [...new Set(eventIds)].filter(Boolean).length;
  return (
    <tr>
      <td>{id}</td>
      <td>{label}</td>
      <td>{sourceId}</td>
      <td>{exportType}</td>
      <td>{eventCount}</td>
      <td>{streamCount}</td>
      <td>{destinationType}</td>
      <td>{created}</td>
      <td>{completed === "0001-01-01T00:00:00" ? "" : completed}</td>
      <td>{status}</td>
      <td>{requestedBy}</td>
    </tr>
  );
};

export default ExportHistoryTableRow;
