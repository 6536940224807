import {
  take,
  put,
  select,
  takeLatest,
  takeEvery,
  delay,
} from "redux-saga/effects";
import { get, put as putApi, post, handleAbortApiCall } from "../ApiClient";
import * as mutations from "./mutations";
import defaultState from "./defaultState";
import _ from "lodash";
import moment from "moment";

const defaultStateClone = JSON.parse(JSON.stringify(defaultState));
const dateTimeFormat = "YYYY-MM-DDTHH:mm";

export function* submitTechSharePublishRequest() {
  while (true) {
    const { caseNumber, techShareReqId } = yield take(
      mutations.SUBMIT_CASE_PUBLISH_REQUEST
    );
    var queryString = "cases/TechSharePublish?";
    if (caseNumber.length > 0) queryString += `caseNumber=${caseNumber}&`;
    if (techShareReqId.length > 0)
      queryString += `techShareReqId=${techShareReqId}`;
    yield post(queryString);
  }
}

export function* submitGenericExportHistorySearchRequest() {
  while (true) {
    yield take(mutations.SEARCH_GENERIC_EXPORT_HISTORY);
    const searchOptions = yield select((state) => state.exportHistoryOptions);
    const exportHistory = yield select((state) => state.exportHistory);
    const pageControls = yield select(
      (state) => state.pageControls.exportHistory
    );
    var body = {};

    if (searchOptions.selectedDestinationHistory !== "all") {
      body.destinationType = searchOptions.selectedDestinationHistory;
    }
    switch (searchOptions.selectedStatusHistory) {
      case "complete":
        body.completeOnly = true;
        break;
      case "incomplete":
        body.incompleteOnly = true;
        break;
      default:
        break;
    }

    var results = yield post(
      `genericExports/search?skip=${exportHistory.length}&take=${pageControls.pageSize}`,
      body
    );
    let foundAll = results.data.data.length < pageControls.pageSize;
    yield put(mutations.setReceivedAllExportHistory(foundAll));

    yield put(
      mutations.storeGenericExportHistorySearchResults(
        exportHistory.concat(results.data.data)
      )
    );
  }
}

export function* createGenericExportRequest() {
  while (true) {
    const { destinationType, exportType, sourceId, label, userName } =
      yield take(mutations.SUBMIT_GENERIC_EXPORT_REQUEST);
    const genericExportRequest = {
      destinationType,
      exportType,
      sourceId,
      label,
    };
    const body = { userName, genericExportRequest };
    yield post("genericexports", body);
  }
}

export function* getCasePublishHistory() {
  while (true) {
    yield take(mutations.REQUEST_CASE_HISTORY);
    const { data: caseHistory } = yield get(`Cases/CasePublishHistory`);
    yield put(mutations.receiveCaseHistory(caseHistory));
  }
}

export function* performCaseSearch() {
  while (true) {
    const { name, value } = yield take(mutations.REQUEST_CASE_SEARCH);
    const caseCriteria = yield select((state) => state.caseCriteria);
    const pageControls = yield select((state) => state.pageControls.case);

    if (name !== undefined) {
      if (value !== undefined) {
        caseCriteria[name] = value;
      } else {
        caseCriteria[name] = defaultStateClone.caseCriteria[name];
      }
    }
    var body = { caseNumber: "" };
    if (!_.isEmpty(caseCriteria.caseNumber)) {
      body.caseNumber = caseCriteria.caseNumber;
    }

    const { data: pageItemCount } = 777; // yield post(`cases/searchcount`, body);
    yield put(mutations.updatePageItemCount("case", pageItemCount));

    const skip = Math.max(
      0,
      (pageControls.pageNumber - 1) * pageControls.pageSize
    );

    const { data: cases } =
      typeof body.caseNumber !== "string" || body.caseNumber.length < 1
        ? yield get(`cases/all?skip=${skip}&take=${pageControls.pageSize}`)
        : yield post(
            `cases/search?skip=${skip}&take=${pageControls.pageSize}`,
            body.caseNumber
          );

    yield put(mutations.caseSearch(caseCriteria, cases));
  }
}

export function* performIncidentSearch() {
  while (true) {
    yield take(mutations.REQUEST_INCIDENT_SEARCH);
    const incidentCriteria = yield select((state) => state.incidentCriteria);
    const incidentPageControls = yield select(
      (state) => state.pageControls.incident
    );
    handleAbortApiCall();

    var body = {};
    if (!_.isEmpty(incidentCriteria.incidentNumber)) {
      body.incidentNumber = incidentCriteria.incidentNumber;
    }
    if (!_.isEmpty(incidentCriteria.incidentDate)) {
      body.incidentDate = incidentCriteria.incidentDate;
    }

    const { data: searchCount } = yield post(`Incidents/SearchCount`, body);

    yield put(mutations.updatePageItemCount("incident", searchCount));
    const skip = Math.max(
      0,
      (incidentPageControls.pageNumber - 1) * incidentPageControls.pageSize
    );

    const { data: incidents } = yield post(
      `incidents/search?skip=${skip}&take=${incidentPageControls.pageSize}`,
      body
    );

    yield put(mutations.incidentSearch(incidents));
  }
}

export function* deselectIncident() {
  while (true) {
    yield take(mutations.REQUEST_INCIDENT_DESELECTION);
    yield put(mutations.incidentDeselected());
  }
}

export function* performIncidentResponseSearch() {
  while (true) {
    const { incidentNumber, rmsNumber, natureCode } = yield take(
      mutations.REQUEST_INCIDENT_SELECTION
    );
    const { data: incidentResponses } = yield get(
      `incidentresponses/${incidentNumber}`
    );
    yield put(
      mutations.incidentSelected(
        incidentNumber,
        rmsNumber,
        natureCode,
        incidentResponses
      )
    );
  }
}

export function* selectIncidentResponse() {
  while (true) {
    const { incidentResponseId } = yield take(
      mutations.REQUEST_INCIDENT_RESPONSE_SELECTION
    );
    const incidentResponse = yield select(
      (state) => state.incidentResponses[incidentResponseId]
    );
    const incidentEventSearchFilter = Object.assign(
      {},
      defaultState.incidentEventSearchFilter,
      {
        officer: !!incidentResponse.officerIds,
        startTimeOffsetInMinutes: 0,
        stopTimeOffsetInMinutes: 0,
      }
    );
    yield put(
      mutations.incidentResponseSelected(
        incidentResponseId,
        incidentEventSearchFilter
      )
    );
    yield put(mutations.requestIncidentEventSearch());
  }
}

export function* performIncidentEventsSearch() {
  while (true) {
    const { name, value } = yield take(mutations.REQUEST_INCIDENT_EVENT_SEARCH);
    const { incidentResponse, incidentEventSearchFilter, incidentNumber } =
      yield select((state) => ({
        incidentResponse: state.incidentResponses[state.incidentResponseId],
        incidentEventSearchFilter: state.incidentEventSearchFilter,
        incidentNumber: state.incidentNumber,
      }));
    if (name) {
      incidentEventSearchFilter[name] = value;
    }

    const incidentEventSearchCriteria = {
      officerIds:
        (incidentEventSearchFilter.officer && incidentResponse.officerIds) ||
        undefined,
      includeUnknown: incidentEventSearchFilter.includeUnknown,
      existingMatches: incidentEventSearchFilter.existingMatches,
      includeLinked: incidentEventSearchFilter.includeLinked,
      startRangeBegin: moment(incidentResponse.dispatchStartTime)
        .utc()
        .add(incidentEventSearchFilter.startTimeOffsetInMinutes, "minutes")
        .format(dateTimeFormat),
      stopRangeEnd: moment(incidentResponse.dispatchClearTime)
        .utc()
        .add(incidentEventSearchFilter.stopTimeOffsetInMinutes, "minutes")
        .format(dateTimeFormat),
      incidentNumber,
    };

    const { data: incidentEvents } = yield post(
      "incidentevents/search",
      incidentEventSearchCriteria
    );

    yield put(
      mutations.incidentEventSearch(incidentEventSearchFilter, incidentEvents)
    );
  }
}

export function* performIncidentEventForExportSearch() {
  while (true) {
    yield take(mutations.REQUEST_INCIDENT_EVENT_FOR_EXPORT_SEARCH);
    console.log("hit");
    const { officerIds, searchDateBegin, searchDateEnd } = yield select(
      (state) => ({
        officerIds: state.incidentEventForExportSearchCriteria.officerIds,
        searchDateBegin:
          state.incidentEventForExportSearchCriteria.searchDateBegin,
        searchDateEnd: state.incidentEventForExportSearchCriteria.searchDateEnd,
      })
    );

    const incidentEventSearchCriteria = {
      officerIds: officerIds || undefined,
      includeLinked: true,
      startRangeBegin: moment(searchDateBegin).format(dateTimeFormat),
      stopRangeEnd: moment(searchDateEnd).format(dateTimeFormat),
    };

    const { data: incidentEvents } = yield post(
      "incidentevents/search",
      incidentEventSearchCriteria
    );

    yield put(
      mutations.incidentEventSearch(incidentEventSearchCriteria, incidentEvents)
    );
  }
}

export function* assignIncidentNumber() {
  while (true) {
    const { incidentEventId } = yield take(
      mutations.REQUEST_ASSIGN_INCIDENT_NUMBER
    );
    const {
      incidentEventIncidentNumber,
      incidentNumberTagName,
      incidentNumber,
      rmsNumberTagName,
      rmsNumber,
      natureCodeTagName,
      natureCode,
    } = yield select((state) => ({
      incidentEventIncidentNumber: state.incidentEvents.find(
        (incidentEvent) => incidentEvent.id === incidentEventId
      ).incident,
      incidentNumberTagName: state.taggingOptions.incidentNumberTagName,
      incidentNumber: state.secondaryTags.incidentNumber,
      rmsNumberTagName: state.taggingOptions.rmsNumberTagName,
      rmsNumber: state.secondaryTags.rmsNumber,
      natureCodeTagName: state.taggingOptions.natureCodeTagName,
      natureCode: state.secondaryTags.natureCode,
    }));
    var body = `[{"eventTagName": "${incidentNumberTagName}","eventTagValue":"${incidentNumber}"}`;
    if (
      rmsNumber !== undefined &&
      typeof rmsNumberTagName === "string" &&
      rmsNumberTagName.length > 0 &&
      rmsNumber.length > 0
    ) {
      body += `,{"eventTagName": "${rmsNumberTagName}","eventTagValue":"${rmsNumber}"}`;
    }
    if (
      natureCodeTagName !== undefined &&
      typeof natureCodeTagName === "string" &&
      natureCodeTagName.length > 0 &&
      natureCode.length > 0
    ) {
      body += `,{"eventTagName": "${natureCodeTagName}","eventTagValue":"${natureCode}"}`;
    }
    body += "]";

    if (
      !incidentEventIncidentNumber ||
      !incidentEventIncidentNumber.includes(incidentNumber)
    ) {
      yield putApi(
        `secondarytags/addsecondarytags/${incidentEventId}`,
        `${body}`
      );
    } else {
      yield putApi(
        `secondarytags/removesecondarytags/${incidentEventId}`,
        `"${incidentNumber}"`
      );
    }

    yield delay(600);
    yield put(mutations.requestIncidentEventSearch());
  }
}

export function* exportIncident() {
  while (true) {
    const { incidentNumber, referenceLabel } = yield take(
      mutations.REQUEST_EXPORT_INCIDENT
    );
    const genericExportOptions = JSON.parse(
      localStorage.getItem("AgencyConfig")
    ).genericExportOptions;
    const exportResponse = yield post("exports/exportincident", {
      incidentNumber,
      referenceLabel,
      genericExportOptions,
    });
    var responseMessage = "Export Status Unknown";
    switch (exportResponse.status) {
      case 200:
        responseMessage = "Export Requested Successfully";
        break;
      case 204:
        responseMessage = "Nothing to Export";
        break;
      default:
        break;
    }
    alert(responseMessage);
    yield put(mutations.exportIncident());
  }
}

export function* reset() {
  while (true) {
    yield take(mutations.REQUEST_RESET);

    yield put(mutations.reset(defaultStateClone));
    yield put(mutations.requestIncidentSearch());
    yield put(mutations.requestCaseSearch());
  }
}

export function* incidentReset() {
  while (true) {
    yield take(mutations.REQUEST_INCIDENT_RESET);

    yield put(mutations.incidentReset(defaultStateClone));
    yield put(mutations.requestIncidentSearch());
  }
}

export function* caseReset() {
  while (true) {
    yield take(mutations.REQUEST_CASE_RESET);

    yield put(mutations.caseReset(defaultStateClone));
    yield put(mutations.requestCaseSearch());
  }
}

function* fetchOfficerList(action) {
  const { data: officers } = yield get(
    "officermappings/entry?skip=" +
      action.skipCount +
      "&take=" +
      action.takeCount
  );

  yield put(mutations.officerList(officers));
}

function* updateOfficerMapping(action) {
  yield post(`officermappings`, {
    dispatchOfficerId: action.dispatchOfficerId,
    officerId: action.officerId,
  });
  yield put(mutations.requestOfficerList());
}

export function* watchOfficerRequests() {
  yield takeLatest(mutations.REQUEST_OFFICER_LIST, fetchOfficerList);
  yield takeEvery(
    mutations.REQUEST_UPDATE_OFFICER_MAPPING,
    updateOfficerMapping
  );
}
