import { connect } from "react-redux";

export const ExportHistoryLoadingMessage = ({ display, loadingMessage }) => {
  return (
    <div className="loadingMessage">
      <h1>
        {loadingMessage ? (
          <span className="secondary">
            <i className="icon-spinner-alt icon-spin" />
            {loadingMessage} . . .
          </span>
        ) : (
          <span>
            <i className="icon-search" />
            {display}
          </span>
        )}
      </h1>
    </div>
  );
};

export const displayLoadingText = ({ state }) => {
  const sBuild = (type, count) => `${count} ${type}${count === 1 ? "" : "s"} `;
  let disp = sBuild("Export", state.exportHistory.length);
  return disp.concat(" Displayed");
};

const mapStateToProps = (state) => ({
  display: displayLoadingText({ state }),
  loadingMessage: state.loadingMessage,
});

const ConnectedExportHistoryLoadingMessage = connect(mapStateToProps)(
  ExportHistoryLoadingMessage
);

export default ConnectedExportHistoryLoadingMessage;
